import api from '@apiInstance';

export default {
  add (params) {
    return api.post('stock_headers', params);
  },
  get (id) {
    return api.fetch(`stock_headers/${id}/detail`);
  },
  async list (params) {
    const response = await api.fetch('/stock_headers', { params });
    return response;
  },
  update (params) {
    return api.patch('stock_headers', params);
  },
  delete (id) {
    return api.remove('stock_headers', id);
  },
};
